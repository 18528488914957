import React from 'react'
import { CustomThemeProvider } from './theme'

import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import { AuthProvider } from '@emerald-works/react-auth'
import { authSettings } from '../libs/amplify'
import { ChatProvider } from './chat'

import { counterExampleSlice } from '../reducers'

export default function RootContextProvider ({ children }) {
  const eventBusAuthTokenConfig = {
    generate: true,
    apiGatewayAddress: process.env.REACT_APP_API_GATEWAY_ADDRESS,
    awsRegion: process.env.REACT_APP_API_GATEWAY_REGION,
    generateTokenEndpoint: '/generate-token'
  }

  const handleAuthRedirection = ({ Auth, federatedSignInParams }) => {
    Auth.federatedSignIn({
      ...federatedSignInParams
    })
  }

  return (
    <CustomThemeProvider>
      <AuthProvider
        amplifyAuthConfig={authSettings()}
        onAuthNeededRedirection={handleAuthRedirection}
        allowGuestUsers
        signInPath='/signin'
        eventBusAuthTokenConfig={eventBusAuthTokenConfig}
      >
        <EventBusProvider
          eventBusURL={process.env.REACT_APP_EVENT_BUS_URL}
          connectionParams={{
          }}
          useAuthProvider
          namespace='app-name'
          initialisers={[
            counterExampleSlice.eventBus.getTotal
          ]}
          options={{
            socketIoUrl: process.env.REACT_APP_EVENT_BUS_SOCKET_IO_URL
          }}
          onConnectionChange={(status) => console.log('Connection onConnectionChange hook with status:', status)}
          onOpen={() => {}}
          onClose={() => console.log('Connection onClose hook')}
        >

          <ChatProvider>
            {children}
          </ChatProvider>

        </EventBusProvider>
      </AuthProvider>
    </CustomThemeProvider>
  )
}
