import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { AppToolbar } from './components'
import AppDrawer from './components/app-drawer'
import Logger from './utils/logger'
import RootContextProvider from './contexts/root'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

function App () {
  return (
    <RootContextProvider>
      <div style={{ display: 'flex' }}>
        <Logger />
        <CssBaseline />
        <AppToolbar />
        <AppDrawer />
        <Box component='main' sx={{ flexGrow: 1, padding: 3 }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '64px'
          }}
          />
          <Outlet />
        </Box>
      </div>
    </RootContextProvider>
  )
}

export default App
