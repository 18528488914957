import makeStyles from '@mui/styles/makeStyles'
const drawerWidth = 240

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  activeLink: {
    color: theme.palette.primary.main
  }
}))
