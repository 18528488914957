import React from 'react'
import { useStyles } from './style'
import { Typography } from '@mui/material'

export default function AppToolbar () {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant='h6' className={classes.title}>
        Loading...
      </Typography>
    </div>
  )
}
