import React, { useState } from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Card, Button, Grid, Box, ButtonGroup, Typography } from '@mui/material'
import CodeSnippet from '../../components/code-snippet'

const CounterRequestResponseExample = () => {
  // Component state variable to hold counter value.
  const [total, setTotal] = useState(0)
  const [error, setError] = useState('')

  // Instantiate getTotal Event
  // Events are how react communicate with our backend.
  // Use `useEvent` to create an event object that knows how to behave based on the lifecycle listeners.
  // In this case the event listeners on the event are simple functions.
  // You can pass a single function or pass an array of functions to
  // all lifecycle listeners. None of the listeners are required they are all optional.
  // The listeners functions are not promises. It just code that should be executed
  // when a lifecycle event of the event happens. All networking work is done by EventBus.
  // With the event created we can now use it. To simplify: here we're just creating the Event.
  // There are more options to events but for now we're going to keep it simple.
  const [getTotal] = useEvent([{
    eventName: 'getTotal',
    onSuccess: (payload) => setTotal(payload.total)
  }])

  // `useEvent` accepts an array of events definitions and returns a arrays of events.
  // For this example we need two more events. Increment and decrement so we can update the counter
  // value.
  // This time we're going to only pass the listeners we expect to use.
  const [increment, decrement, reset] = useEvent([
    {
      eventName: 'increment',
      onSuccess: (payload) => setTotal(payload.total),
      onError: (payload) => setError(JSON.stringify(payload))
    },
    {
      eventName: 'decrement',
      onSuccess: (payload) => setTotal(payload.total)
    },
    {
      eventName: 'reset',
      onSuccess: (payload) => setTotal(payload.total)
    }
  ])

  // React-event-bus-client provides a utility: `useEventsOnViewLoad`
  // if you want to use the event soon as it became available,
  // use this utility. The callback will be trigged when the event is available.
  // In this case we want to call the backend to get the current total.
  // It's similar to a HTTP request to load data on page load. Here is where you'll be doing.
  // fun fact - for mobile developer: `useEventsOnViewLoad` it's like viewDidLoad on iOS.
  useEventsOnViewLoad(() => {
    // send a message to backend to run the lambda function mapped to this endpoint.
    // the results, errors, burst lifecycle events are handle but the function defined on the
    // burst creation.
    // It's like sending the http request.

    getTotal.trigger()
  }, [getTotal])

  // with the increment/decrement event created we just need to write html and hook the events up.
  // When the user clicks on the `+` button: trigger event to increment and update the total
  // with the result and let react do the rest. The same for the `-` button.

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <h3>Simple fetch Counter</h3>
        <p>This is a simple example on how the <CodeSnippet>EventBus</CodeSnippet> works. It doesn't use Redux to trigger the events, only the component itself.</p>
        <p>It works like a common HTTP request, but it runs to the WebSocket protocol, but instead of use the common HTTP request, it works though the WebSocket. You can take a look into your <CodeSnippet>console.log</CodeSnippet> and see what is going on.</p>
        <p>Here, the counter result is <b>NOT</b> expected to be broadcasted to all connected peers. </p>
        <Card>
          <Box p={2}>
            <Box p={1}>
              <h3>Total:</h3>
              <Typography variant='h2'><span data-test='counter-result' data-value={total}>{total}</span></Typography>
            </Box>

            <ButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
              <Button
                variant='contained'
                disabled={increment.isWorking}
                data-test='counter-increment'
                onClick={() => increment.trigger()}
              >
                +
              </Button>
              <Button
                variant='contained'
                disabled={decrement.isWorking}
                data-test='counter-decrement'
                onClick={() => decrement.trigger()}
              >
                -
              </Button>
              <Button
                variant='contained'
                disabled={reset.isWorking}
                data-test='counter-reset'
                onClick={() => reset.trigger()}
              >
                Reset
              </Button>
            </ButtonGroup>
            <h3>{error}</h3>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CounterRequestResponseExample
