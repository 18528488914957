import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  card: {
    maxWidth: 500,
    margin: '10px auto',
    padding: 20
  }
}))
