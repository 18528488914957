import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  todoList: {
    width: 500,
    marginTop: '10px'
  },
  todoInput: {
    width: 400
  },
  realTimeLogs: {
    width: 500
  }
}))
