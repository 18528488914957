import React from 'react'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './reducers/store'
import './libs/i18n'
import { RouterProvider } from 'react-router-dom'
import ApplicationRoutes from './routes'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <RouterProvider router={ApplicationRoutes} future={{ v7_startTransition: true }} />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
