import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    padding: 4,
    borderRadius: 2,
    fontWeight: 'bold'
  }
}))
