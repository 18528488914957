import React from 'react'
import { useStyles } from './style'
import { chatExampleSlice } from '../../reducers'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { useSelector } from 'react-redux'

const Chat = () => {
  const classes = useStyles()

  const messages = useSelector(chatExampleSlice.selectors.selectMessages)

  const { register, handleSubmit, reset } = useForm()

  const [getMessages, sendMessage, clearMessages] = useEvent([
    chatExampleSlice.eventBus.getMessages,
    chatExampleSlice.eventBus.sendMessage,
    chatExampleSlice.eventBus.clearMessages
  ])

  useEventsOnViewLoad(() => {
    getMessages.trigger()
  }, [getMessages])

  const onSubmit = data => {
    reset()
    sendMessage.trigger({
      message: data.message
    })
  }

  function handleClearMessagesClick () {
    clearMessages.trigger()
  }

  return (
    <div className={classes.root}>

      <p> open more than one browser at this same address and check the realtime chat </p>

      {messages.map((message, index) => {
        return (
          <p key={index}>
            {index + 1}: {message.text}
          </p>
        )
      })}

      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>
          Send Message
        </h6>

        <div>
          <TextField variant='standard' {...register('message')} name='message' data-test='message' />
        </div>

        <input type='submit' data-test='send-message' />
      </form>
      <button onClick={handleClearMessagesClick} data-test='clear-chat'>Clear Chat</button>

    </div>
  )
}

export default Chat
