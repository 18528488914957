import React, { useState, Suspense } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useStyles } from './style'
import { AppLoading } from '../../components'
import Typography from '@mui/material/Typography'

const I18nPage = () => {
  // load translation file
  const { t, i18n } = useTranslation(['common', 'home-page'])
  const [unread, setUnread] = useState(0)
  const classes = useStyles()

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  return (
    <div className={classes.root}>

      <Typography variant='h3'> i18n Examples </Typography>

      <Typography> We use a series of i18n libs to make the internationalization. </Typography>
      <Typography> If you go to `src/libs/i18n.js`, you'll see how the i18n is initialize </Typography>

      <Typography> The main way to use the translation files is throught `react-i18next`. </Typography>
      <Typography> <a href='https://react.i18next.com/' target='__blank'> react-i18next docs </a> </Typography>

      <br />
      <br />

      <div>
        <div>
          {/* load a template to translate. */}
          <Trans
            i18nKey='common:description.part1'
            defaults='To get started, edit <b><italic>src/App.js </italic></b> and save to reload.'
            components={{ italic: <i />, b: <strong /> }}
          />

          {/* mually change the language */}
          <button onClick={() => changeLanguage('pt')}>pt</button>
          <button onClick={() => changeLanguage('en')}>en</button>
        </div>

        {/* Loading text from lang file based on the key */}
        <div>{t('common:description.part2')}</div>
        <div>{t('home-page:sample-text')}</div>
        <div>
          <Trans t={t} i18nKey='common:message_notfication' count={unread}>
            You have <strong>{{ unread }}</strong> unread message
          </Trans>
        </div>
        <div>
          <button onClick={() => setUnread(unread - 1)}>-</button>
          <button onClick={() => setUnread(unread + 1)}>+</button>
        </div>
      </div>
    </div>
  )
}

// Pages or components that uses the i18n feature needed to be suspensed
// while the location file is loaded from the server the fallback component will show up
// I18nPage is an "async" component and therefore can be suspensed.
const I18nPageSuspensed = () => {
  return (
    <Suspense fallback={<AppLoading />}>
      <I18nPage />
    </Suspense>
  )
}

export default I18nPageSuspensed
